import { dataCurrent, asxYear, asxCode, asxRegion, dataTest } from "@/services";

export default {
  namespaced: true,
  state: {
    loading: true,
    spinner: false,
    filters: [],
    selected: {
      region: "NSW",
      year: 2024,
      asxCode: "BNZ2024",
    },
    regions: [],
    isUpdate: false,
    trades: [],
  },
  getters: {
    trades: (state) => state.trades,
  },
  actions: {
    async getData({ commit, state }) {
      try {
        commit("SET_IS_UPDATE", false);
        commit("SET_SPINNER", true);
        const response = await dataCurrent(state.selected.asxCode);
        if (response.code === 0) {
          response.data.trades.sort(
            (a, b) =>
              new Date(b.settlementAt).getTime() -
              new Date(a.settlementAt).getTime()
          );

          commit("SET_TRADES", response.data.trades);
          commit("SET_SPINNER", false);
        }
      } catch (error) {
        window.location.href = "/auth/login";
      }
    },

    async getFilter({ commit, state }) {
      try {
        const response = await asxRegion();
        if (response.code === 0) {
          commit("SET_REGIONS", response.data.regions);

          try {
            const response = await asxYear(state.selected.region);
            if (response.code === 0) {
              let filters = [];

              for (let index = 0; index < response.data.years.length; index++) {
                const element = response.data.years[index];

                try {
                  const response = await asxCode(
                    state.selected.region,
                    element
                  );
                  if (response.code === 0) {
                    filters.push({
                      year: element,
                      asxCode: response.data.asxCodes,
                    });
                  }
                } catch (error) {
                  console.log(error);
                }
              }

              commit("SET_FILTERS", filters);
              commit("SET_LOADING", false);
            }
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async onChangeRegion({ commit, dispatch }, value) {
      try {
        commit("SET_SPINNER", true);
        commit("SET_FILTERS", []);
        const response = await asxYear(value);
        if (response.code === 0) {
          let filters = [];

          for (let index = 0; index < response.data.years.length; index++) {
            const element = response.data.years[index];

            try {
              const response = await asxCode(value, element);
              if (response.code === 0) {
                filters.push({
                  year: element,
                  asxCode: response.data.asxCodes,
                });
              }
            } catch (error) {
              console.log(error);
            }
          }
          commit("SET_SELECTED", {
            region: value,
            year: filters[0].year,
            asxCode: filters[0].asxCode[0],
          });
          commit("SET_FILTERS", filters);
          commit("SET_SPINNER", false);
          dispatch("getData");
        }
      } catch (error) {
        console.log(error);
      }
    },

    async onTest() {
      try {
        await dataTest({
          settlementAt: "2024-10-23T00:28:10.000Z",
          futurePrice: 99.5,
          futureSource: "snapshot",
          optionPrice: 9.9,
          optionType: "put",
          period: "Q4",
          strikePrice: 95,
          volatility: 62.289691085275976,
          volume: 25,
          year: 2024,
          movingAvg: 62.289691085275976,
          asxCode: "BNZ2024",
          delta: Math.random() * (60 - 1) + 1,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },

  mutations: {
    SET_LOADING(state, data) {
      state.loading = data;
    },
    SET_SPINNER(state, data) {
      state.spinner = data;
    },
    SET_REGIONS(state, data) {
      state.regions = data;
    },
    SET_ASX_CODE(state, data) {
      state.asxCode = data;
    },
    SET_FILTERS(state, data) {
      state.filters = data;
    },
    SET_SELECTED(state, data) {
      Object.entries(data).map(([key, value]) => {
        state.selected[key] = value;
      });
    },
    SET_IS_UPDATE(state, data) {
      state.isUpdate = data;
    },
    SET_TRADES(state, data) {
      state.trades = data;
    },
  },
};
