import { authRefresh } from "@/services";

export default async function checkAuth({ next }) {
  try {
    const response = await authRefresh();
    if (response.code === 0) {
      localStorage.setItem("asx_api_access_token", response.data.accessToken);
      next();
    } else {
      localStorage.clear();
      next("/auth/login");
    }
  } catch (error) {
    localStorage.clear();
    next("/auth/login");
  }
}
