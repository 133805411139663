import { axios } from "../axios";

export const authLogin = async () => {
  try {
    const { data } = await axios.get(`/auth/login`);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const authToken = async (payload) => {
  try {
    const { data } = await axios.post(`/auth/token`, payload);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const authRefresh = async () => {
  try {
    const { data } = await axios.post(`/auth/refresh`);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};
