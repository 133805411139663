import { authRefresh } from "@/services";

export default async function checkGuest({ next }) {
  try {
    const response = await authRefresh();
    if (response.code === 0) {
      localStorage.setItem("asx_api_access_token", response.data.accessToken);
      next("/");
    } else {
      localStorage.clear();
      next();
    }
  } catch (error) {
    localStorage.clear();
    next();
  }
}
