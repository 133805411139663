import { authLogin, authToken } from "@/services";

export default {
  namespaced: true,
  state: {
    loading: false,
    isCollapse: false,
  },
  actions: {
    async handleLogin({ commit }) {
      try {
        commit("SET_LOADING", true);
        const response = await authLogin();
        if (response.code === 0) {
          window.location.href = response.data.url;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleToken(_, payload) {
      try {
        const response = await authToken(payload);
        if (response.code === 0) {
          localStorage.setItem(
            "asx_api_access_token",
            response.data.accessToken
          );
          localStorage.setItem(
            "asx_api_refresh_token",
            response.data.refreshToken
          );
          window.location.href = "/";
        } else {
          window.location.href = "/auth/login";
        }
      } catch (error) {
        console.log(error);
      }
    },

    handleLogout() {
      localStorage.clear();
      window.location.href = "/auth/login";
    },

    handleCollapse({ commit, state }) {
      let isCollapse = !state.isCollapse;
      if (isCollapse) {
        document.body.classList.add("collapse-filter");
      } else {
        document.body.classList.remove("collapse-filter");
      }
      commit("SET_IS_COLLAPSE", isCollapse);
    },
  },

  mutations: {
    SET_LOADING(state, data) {
      state.loading = data;
    },
    SET_IS_COLLAPSE(state, data) {
      state.isCollapse = data;
    },
  },
};
