import Axios from "axios";

const instance = Axios.create({
  baseURL: process.env.VUE_APP_API_URI,
  headers: {
    "x-api-key": process.env.VUE_APP_API_KEY,
  },
  timeout: 120000,
});
const axios = instance;

axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("asx_api_access_token");
    const refreshToken = localStorage.getItem("asx_api_refresh_token");
    if (config.url == "/auth/refresh") {
      if (refreshToken) {
        config.headers["Authorization"] = "Bearer " + refreshToken;
      }
    } else {
      if (accessToken) {
        config.headers["Authorization"] = "Bearer " + accessToken;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axios };
