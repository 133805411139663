<template>
  <component :is="$route.meta.layout || 'div'">
    <router-view />
  </component>
</template>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.header {
  line-height: 3rem;
  background-color: #002539;
}
.main {
  margin-top: 3rem;
  height: calc(100vh - 3rem);
}
.sidebar {
  width: 12rem;
  transition-duration: 500ms;
  transition-property: left;
}
.collapse-filter .sidebar {
  left: -12rem !important;
}
.collapse-filter .content {
  margin-left: 0;
}
.content {
  margin-left: 12rem;
  transition-duration: 500ms;
  transition-property: margin-left;
}
.filter-header {
  height: 2.8rem;
}
.filter-body {
  height: calc(100vh - 5.8rem);
}
.page-header {
  height: 2.8rem;
}
.page-body {
  height: calc(100vh - 5.8rem);
}
table {
  font-size: 0.8rem;
}

.table-primary > td {
  color: #005589 !important;
  background-color: #ecf8ff !important;
}

.form-check-input:checked,
.btn.active,
.btn-primary,
.btn-primary:hover,
.btn-outline-primary:hover {
  background-color: #005589;
  border-color: #005589;
}

.btn-outline-primary {
  border-color: #005589;
  color: #005589;
}
.text-primary {
  color: #005589 !important;
}
.accordion-button:not(.collapsed) {
  background: none;
}
</style>
