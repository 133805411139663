<template>
  <header class="header position-fixed top-0 start-0 end-0 z-1">
    <div class="container-fluid">
      <div class="d-flex justify-content-between align-items-center">
        <a
          class="navbar-brand text-white-50 text-uppercase fs-6 fw-bold"
          href="/"
          >asx-poc-web</a
        >
        <button class="btn" @click="handleLogout">
          <small class="ms-1 text-white-50">Logout</small>
        </button>
      </div>
    </div>
  </header>
</template>

<script setup>
import { useStore } from "vuex";
const store = useStore();

function handleLogout() {
  store.dispatch("auth/handleLogout");
}
</script>
