import { axios } from "../axios";

export const asxCode = async (region, year) => {
  try {
    const { data } = await axios.get(
      `/asx/codes?region=${region}&year=${year}`
    );
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const asxYear = async (region) => {
  try {
    const { data } = await axios.get(`/asx/years?region=${region}`);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const asxRegion = async () => {
  try {
    const { data } = await axios.get(`/asx/regions`);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};
