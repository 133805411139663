import { computed } from "vue";
import { useStore } from "vuex";
import moment from "moment-timezone";
import Pusher from "pusher-js/with-encryption";

export function usePusher() {
  const store = useStore();
  const data = computed(() => {
    return store.state.data;
  });
  const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    channelAuthorization: {
      endpoint: `${process.env.VUE_APP_API_URI}/pusher/auth`,
      headers: {
        "x-api-key": process.env.VUE_APP_API_KEY,
        "x-csrf-token": localStorage.getItem("asx_api_access_token"),
      },
    },
  });
  const channel = pusher.subscribe("private-encrypted-blocktrade");
  channel.bind("blocktrade", (response) => {
    response.data.settlementAt = moment
      .tz(new Date(), "Australia/Sydney")
      .format();

    if (
      response.data &&
      response.data.asxCode &&
      data.value.selected.asxCode === response.data.asxCode
    ) {
      data.value.isUpdate = true;
      data.value.trades.unshift(response.data);
    }
  });
}
