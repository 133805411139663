<template>
  <template v-if="data.loading">
    <BaseLoading />
  </template>
  <template v-else>
    <BaseHeader />
    <main class="main overflow-hidden position-relative">
      <div class="sidebar position-absolute top-0 start-0 bottom-0 border-end">
        <BaseFilter />
      </div>

      <div class="content">
        <slot />
      </div>
    </main>
  </template>
</template>

<script setup>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseFilter from "@/components/BaseFilter.vue";
import { usePusher } from "@/composables/usePusher";

usePusher();
const store = useStore();
const data = computed(() => {
  return store.state.data;
});

onMounted(() => {
  store.dispatch("data/getFilter");
});
</script>
