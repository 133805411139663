<template>
  <div
    class="filter-header border-bottom d-flex justify-content-between align-items-center px-3"
  >
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-funnel"
        viewBox="0 0 16 16"
      >
        <path
          d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z"
        />
      </svg>
      <small class="ms-1"><strong>Filters</strong></small>
    </div>
    <button class="btn p-0 border-0" @click="onIsCollapse">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-arrow-left-square"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
        />
      </svg>
    </button>
  </div>
  <div class="filter-body px-3 py-2 overflow-auto">
    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label">
        <small><strong>Region</strong></small>
      </label>

      <div
        class="form-check"
        v-for="(item, index) in data.regions"
        v-bind:key="index"
      >
        <input
          class="form-check-input"
          type="radio"
          :id="item"
          name="region"
          :checked="item === data.selected.region"
          @change="onChangeRegion(item)"
          :disabled="data.spinner"
        />
        <label class="form-check-label" :for="item">
          <small>{{ item }}</small>
        </label>
      </div>
    </div>
    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label">
        <small><strong>Year & ASX Code</strong></small></label
      >
      <div
        class="accordion accordion-flush"
        id="accordionFlushExample"
        v-if="data.filters.length"
      >
        <div
          v-for="(item, index) in data.filters"
          class="accordion-item"
          v-bind:key="index"
        >
          <button
            :class="
              item.year === data.selected.year
                ? 'accordion-button px-0 py-2'
                : 'accordion-button collapsed px-0 py-2'
            "
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#' + index"
            :aria-expanded="item.year === data.selected.year ? true : false"
          >
            <small>{{ item.year }}</small>
          </button>
          <div
            :id="index"
            :class="
              item.year === data.selected.year
                ? 'accordion-collapse collapse show'
                : 'accordion-collapse collapse'
            "
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body p-2">
              <div
                class="form-check"
                v-for="(subItem, subIndex) in item.asxCode"
                v-bind:key="subIndex"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="asxCode"
                  :id="subItem"
                  :checked="subItem === data.selected.asxCode"
                  @change="onChangeASXCode(subItem)"
                  :disabled="data.spinner"
                />
                <label class="form-check-label" :for="subItem">
                  <small>{{ subItem }} </small>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const data = computed(() => {
  return store.state.data;
});

function onChangeRegion(value) {
  store.dispatch("data/onChangeRegion", value);
}

function onChangeASXCode(value) {
  data.value.selected.asxCode = value;
  store.dispatch("data/getData");
}

function onIsCollapse() {
  store.dispatch("auth/handleCollapse");
}
</script>
